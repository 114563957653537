import { render, staticRenderFns } from "./redPacketPreview.vue?vue&type=template&id=42a4a715&"
import script from "./redPacketPreview.vue?vue&type=script&lang=js&"
export * from "./redPacketPreview.vue?vue&type=script&lang=js&"
import style0 from "./redPacketPreview.vue?vue&type=style&index=0&id=42a4a715&prod&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports