var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('b-modal',{attrs:{"id":"modal-scrollable_history","scrollable":"","title":"聊天记录","hide-footer":true,"size":"lg"}},[_c('div',{staticClass:"chatWindowBody"},[_c('ul',{staticClass:"chatWindowBodyUl"},_vm._l((_vm.historyData),function(item,index){return _c('li',{key:index},[_c('div',{class:[
            item.sender === _vm.selectedUser.number ? 'receive' : 'sent',
          ]},[_c('img',{class:[
              item.sender === _vm.selectedUser.number ? 'imageleft' : 'imageright',
            ],attrs:{"src":_vm.avatorImg}}),_c('div',{staticClass:"talktext"},[(item.type == 0)?_c('p',{staticStyle:{"word-wrap":"break-word"},domProps:{"innerHTML":_vm._s(_vm.processContent(
                item.message.replace(/(?:\r\n|\r|\n)/g, '<br />')
              )
                )}}):_vm._e(),(item.type == 1)?_c('b-img',{staticStyle:{"width":"auto","height":"200px","max-width":"600px"},attrs:{"src":item.message},on:{"click":function($event){_vm.showImg(_vm.$baseStoragePath + JSON.parse(item.message).image)}}}):_vm._e(),(item.type == 2)?_c('audio',{attrs:{"controls":"","src":_vm.$baseStoragePath + JSON.parse(item.message).file,"type":"audio/mpeg"}}):_vm._e(),(item.type == 8)?_c('b-embed',{attrs:{"type":"video","aspect":"4by3","controls":""}},[_c('source',{attrs:{"src":item.message,"type":"video/mp4"}})]):_vm._e(),(item.type == 3)?_c('div',{on:{"click":function($event){return _vm.onFile(item.message)}}},[_c('img',{staticStyle:{"width":"auto","height":"100px"},attrs:{"src":_vm.getFileType(item.message)}}),_c('p',{staticStyle:{"text-align":"right","color":"#ced4da","font-size":"13px"}})]):_vm._e(),_c('p',{staticStyle:{"text-align":"right","color":"#ced4da","font-size":"13px"}},[_vm._v(" "+_vm._s(_vm.getTime(item.create_time))+" ")])],1)])])}),0),(_vm.loadMoreButton)?_c('div',{staticClass:"chatHistory",attrs:{"id":"userChatLog"}},[_c('b-button',{staticStyle:{"background":"#2c3e50"},on:{"click":_vm.loadHistory}},[_vm._v(" 查看更多记录 ")])],1):_vm._e()])])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }