<template>
  <div>
    <b-modal id="modal-scrollable_history" scrollable title="聊天记录" :hide-footer="true" size="lg">
      <div class="chatWindowBody">
        <ul class="chatWindowBodyUl">
          <!-- ---------------------------------- Send Recieve message UI ------------------------------------>
          <li v-for="(item, index) in historyData" :key="index">
            <div v-bind:class="[
              item.sender === selectedUser.number ? 'receive' : 'sent',
            ]">
              <img :src="avatorImg" v-bind:class="[
                item.sender === selectedUser.number ? 'imageleft' : 'imageright',
              ]" />
              <div class="talktext">
                <p v-if="item.type == 0" style="word-wrap: break-word" v-html="processContent(
                  item.message.replace(/(?:\r\n|\r|\n)/g, '<br />')
                )
                  "></p>
                <b-img v-if="item.type == 1" :src="item.message" @click="
                  showImg($baseStoragePath + JSON.parse(item.message).image)
                  " style="width: auto; height: 200px; max-width: 600px"></b-img>
                <!-- ================================= voice record messages ========================= -->
                <audio controls v-if="item.type == 2" :src="$baseStoragePath + JSON.parse(item.message).file"
                  type="audio/mpeg"></audio>
                <b-embed v-if="item.type == 8" type="video" aspect="4by3" controls>
                  <source :src="item.message" type="video/mp4" />
                </b-embed>
                <div v-if="item.type == 3" @click="onFile(item.message)">
                  <img v-bind:src="getFileType(item.message)" style="width: auto; height: 100px" />
                  <p style="text-align: right; color: #ced4da; font-size: 13px">
                    <!-- {{ item.message.substring(0, 20) }} -->
                  </p>
                </div>
                <p style="text-align: right; color: #ced4da; font-size: 13px">
                  {{ getTime(item.create_time) }}
                </p>
              </div>
            </div>
          </li>
        </ul>
        <div class="chatHistory" id="userChatLog" v-if="loadMoreButton">
          <b-button style="background: #2c3e50" @click="loadHistory">
            查看更多记录
          </b-button>
        </div>
      </div>
    </b-modal>
  </div>
</template>
<script>
import Vue from "vue";
import moment from "moment";
import hevueImgPreview from "hevue-img-preview";
Vue.use(hevueImgPreview);
export default {
  name: "History",
  props: ["selectedUser"],
  data() {
    return {
      avatorImg: require("../assets/img/chat_files/avator.png"),
      loadMoreButton: false,
      sendImage: [
        "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQx1e3Brs1-mXteyLcxqZxVXQQhMQLKUcnLxA&usqp=CAU",
      ],
      historyPageNo: 1,
      historyData: [],
      historyDataTemp: [],
      emojiIcons: [
        {
          id: 0,
          text: "../assets/img/emoji_files/0.png",
          key: "[微笑]",
        },
        {
          id: 1,
          text: this.$baseURL + "../assets/img/emoji_files/1.png",
          key: "[爱你]",
        },
        {
          id: 2,
          text: this.$baseURL + "../assets/img/emoji_files/2.png",
          key: "[拜拜]",
        },
        {
          id: 3,
          text: this.$baseURL + "../assets/img/emoji_files/3.png",
          key: "[悲伤]",
        },
        {
          id: 4,
          text: this.$baseURL + "../assets/img/emoji_files/4.png",
          key: "[鄙视]",
        },
        {
          id: 5,
          text: this.$baseURL + "../assets/img/emoji_files/5.png",
          key: "[闭嘴]",
        },
        {
          id: 6,
          text: this.$baseURL + "../assets/img/emoji_files/6.png",
          key: "[馋嘴]",
        },
        {
          id: 7,
          text: this.$baseURL + "../assets/img/emoji_files/7.png",
          key: "[吃惊]",
        },
        {
          id: 8,
          text: this.$baseURL + "../assets/img/emoji_files/8.png",
          key: "[鼓掌]",
        },
        {
          id: 9,
          text: this.$baseURL + "../assets/img/emoji_files/9.png",
          key: "[哈哈]",
        },
        {
          id: 10,
          text: this.$baseURL + "../assets/img/emoji_files/10.png",
          key: "[害羞]",
        },
        {
          id: 11,
          text: this.$baseURL + "../assets/img/emoji_files/11.png",
          key: "[黑线]",
        },
        {
          id: 12,
          text: this.$baseURL + "../assets/img/emoji_files/12.png",
          key: "[可爱]",
        },
        {
          id: 13,
          text: this.$baseURL + "../assets/img/emoji_files/13.png",
          key: "[可怜]",
        },
        {
          id: 14,
          text: this.$baseURL + "../assets/img/emoji_files/14.png",
          key: "[亲亲]",
        },
        {
          id: 15,
          text: this.$baseURL + "../assets/img/emoji_files/15.png",
          key: "[生病]",
        },
        {
          id: 16,
          text: this.$baseURL + "../assets/img/emoji_files/16.png",
          key: "[偷笑]",
        },
        {
          id: 17,
          text: this.$baseURL + "../assets/img/emoji_files/17.png",
          key: "[挖鼻]",
        },
        {
          id: 18,
          text: this.$baseURL + "../assets/img/emoji_files/18.png",
          key: "[委屈]",
        },
      ],
    };
  },
  methods: {
    loadHistory() {
      let that = this;
      let elmentLoadMore = document.getElementById("userChatLog");
      if (!elmentLoadMore) {
        that.historyPageNo = 1;
      } else {
        that.historyPageNo++;
      }
      const historyPayload = {
        page: that.historyPageNo,
        limit: 20,
        user_id: that.$store.state.number,
        receiver: that.selectedUser.number
      }
      
      that
        .$http({
          url:
            `${that.$baseURL}/app/Chat/getSingleHistory`,
          method: "post",
          headers: {
            
          },
          data: historyPayload,
        })
        .then((res) => {
          if (res.status == 200) {
            that.historyDataTemp = res.data.records;
            if (that.historyPageNo != 1) {
              that.historyData = that.historyData.concat(that.historyDataTemp);
            } else {
              that.historyData = that.historyDataTemp;
            }
            //  Hide/show loadmore button
            if (that.historyDataTemp.length >= 20) {
              that.loadMoreButton = true;
            } else {
              that.loadMoreButton = false;
            }

          }else if(res.data.status == 401){
            this.$router.push({ name: "Login" });
          }
        }).catch((e)=>{
          console.error(e.message);
        });
    },
    getTime(timestamp) {
      return moment(timestamp).format("MMMM Do YYYY, h:mm:ss a");
    },
    getFileType(content) {
      let fileNameArray = content.split(".");
      let fileType = fileNameArray[fileNameArray.length - 1];
      switch (fileType) {
        case "pdf":
          return require("../assets/img/chat_files/pdf.png");
        case "doc":
          return require("../assets/img/chat_files/doc.png");
        case "docx":
          return require("../assets/img/chat_files/doc.png");
        case "ppt":
          return require("../assets/img/chat_files/ppt.png");
        case "xls":
          return require("../assets/img/chat_files/xls.png");
        case "xlsx":
          return require("../assets/img/chat_files/xls.png");
        case "jpeg":
          return require("../assets/img/chat_files/jpeg.png");
        case "jpg":
          return require("../assets/img/chat_files/jpeg.png");
        case "mp3":
          return require("../assets/img/chat_files/mp3.png");
        case "png":
          return require("../assets/img/chat_files/png.png");
        case "rar":
          return require("../assets/img/chat_files/rar.png");
        case "txt":
          return require("../assets/img/chat_files/txt.png");
        case "zip":
          return require("../assets/img/chat_files/zip.png");
        case "gif":
          return require("../assets/img/chat_files/gif.png");
        default:
          return require("../assets/img/chat_files/def.png");
      }
    },
    onFile(content) {
      // let url = this.$baseStoragePath + JSON.parse(content).file;
      window.open(content);
    },
    showImg(url) {
      this.$hevueImgPreview(url);
    },
    processContent(content) {
      let that = this;
      content = content.replace(/face\[([^\s[\]]+?)\]/g, function (face) {
        //转义表情
        var alt = face.replace(/^face/g, "");
        var imgPath = that.emojiIcons.find((o) => alt === o.key);
        return (
          '<img alt="' +
          alt +
          '" title="' +
          alt +
          '" src="' +
          require(`../assets/img/emoji_files/${imgPath.id}.png`) +
          '">'
        );
      });
      return content;
    },
  },
};
</script>
<style scoped>
.chatWindowBody ul {
  padding-left: 0px;
}

.chatWindowBody ul li {
  list-style: none;
}

.imageleft {
  width: 30px;
  border-radius: 50%;
  float: left;
  margin: 6px 8px 0 0;
  height: 30px;
}

.sent {
  margin: 15px 0px;
  width: 100%;
  display: inline-block;
  text-align: right;
}

.imageright {
  width: 30px;
  border-radius: 50%;
  float: right;
  margin: 6px 0 0 8px;
  height: 30px;
}

.talktext {
  display: inline-block;
  position: relative;
  width: auto;
  height: auto;
  background-color: #435f7a;
  padding: 10px 15px;
  border-radius: 20px;
  color: #ffffff;
}

.talktext p {
  margin: 0px;
  max-width: 500px;
  text-align: justify;
}

.receive {
  float: left;
  margin: 15px 0px;
  width: 100%;
  display: inline-flex;
}

.chatHistory {
  text-align: center;
}
</style>
<style>
.hevue-imgpreview-wrap {
  z-index: 10000;
}
</style>
