<template>
    <!-- ========================================= Send Red Packet Modal ============================================ -->
    <b-modal v-model="sendRedPacketModal" centered size="m" @hidden="closeModel" @shown="opened">
        <template #modal-header>
            <h5 style="color: #fff;">发红包</h5>
        </template>

        <template #default>
            <b-form>
                <b-form-group label="红包类型:" label-for="red-packet-type" label-cols="3" label-align-sm="right">
                    <b-form-select id="red-packet-type" v-model="selectedRedPacket" :options="allRedPackets" text-field="name" value-field="id"></b-form-select>
                </b-form-group>
                <b-form-group label="金额:" label-for="amount" label-cols="3" label-align-sm="right">
                    <b-form-input id="amount" type="number" v-model="amount" aria-label="amount"></b-form-input>
                </b-form-group>
            </b-form>

            <b-alert :show="showError" variant="danger" dismissible @dismissed="showError = false" fade>{{ errorText }}</b-alert>
        </template>

        <template #modal-footer="{ ok }">
            <b-button size="sm" variant="success" @click="submit()"> 赠送 </b-button>
            <b-button id="closeButton" size="sm" @click="ok()">取消</b-button>
        </template>
    </b-modal>
</template>


<script>

export default {
  name: "SendRedPacket",
  props:{
    sendRedPacketModal: {
				type: Boolean,
				default: false,
			}
  },
  data() {
    return {
        // sendRedPacketModal:false,
        selectedRedPacket:'',
        allRedPackets:[],
        amount:0,
        showError: false,
        errorText: ''
    }
},
methods: {
    closeModel(){
        this.sendRedPacketModal = false
        this.selectedRedPacket = '';
        this.amount = '';
        this.$emit('closeSendRedPacket')
    },
    getTypes() {
        let that = this;

        that
            .$http({
                url: `${that.$baseURL}/app/RedPacket/type/list`,
                method: "post",
                headers: {
                    "Authorization": that.$store.state.token,
                },
            })
            .then((res) => {
                if (res.data.status == 200) {
                    that.allRedPackets = res.data.data;
                } else if (res.data.status == 401) {
                    this.$router.push({ name: "Login" });
                }
            })
            .catch((res) => {
                console.log(res.msgId);
            })
    },
    submit() {
        if (this.selectedRedPacket && this.amount) {
            this.$emit("createRedPacket", this.selectedRedPacket, this.amount);
        }
    },
    opened() {
        this.getTypes()
    },
    showErrBadge(msg) {
        this.errorText = msg;
        this.showError = true;
    }
},
mounted() {
    
}



}
</script>
<style>
.modal-header{
    border-bottom: var(--bs-modal-header-border-width)
}
</style>