<template>
  <b-container
    fluid
    style="
      background-image: url('../assets/img/pattern.png');
          repeat,
        linear-gradient(#e1e4e7, #f3f4f5);
      height: 100vh;
    "
  >
    <b-row class="justify-content-md-center">
      <b-col col lg="2"></b-col>
      <b-col cols="12" md="auto">
        <b-card-group
          style="
            margin: 100px auto;
            width: 400px;
            box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
          "
        >
          <b-card header-class="headerStyle" footer-class="footerStyle">
            <template #header>
              <img
                :src="imageUrl"
                class="imageLogo"
              >
              <p style="font-size: 18px">Web客户端登录</p>
            </template>
            <b-card-text>
              <b-input-group size="lg" class="mb-2" style="padding: 12px 0px">
                <b-input-group-prepend is-text>
                  <b-icon icon="person-fill"></b-icon>
                </b-input-group-prepend>
                <b-form-input
                  type="text"
                  placeholder="帐号"
                  style="font-size: 14px; height: 50px"
                  v-model="account"
                ></b-form-input>
              </b-input-group>
              <b-input-group size="lg" class="mb-2" style="padding-bottom: 6px">
                <b-input-group-prepend is-text>
                  <b-icon icon="lock-fill"></b-icon>
                </b-input-group-prepend>
                <b-form-input
                  type="password"
                  placeholder="密码"
                  style="font-size: 14px; height: 50px"
                  v-model="password"
                ></b-form-input>
              </b-input-group>
              <b-alert
                :show="showError"
                variant="danger"
                dismissible
                @dismissed="showError = false"
                fade
                >{{ errorText }}</b-alert
              >
            </b-card-text>
            <template #footer>
              <b-button
                block
                variant="success"
                style="padding: 12px 10px"
                @click="onLogin"
                >登录</b-button
              >
            </template>
          </b-card>
        </b-card-group>
      </b-col>
      <b-col col lg="2"></b-col>
    </b-row>
  </b-container>
</template>

<script>
import md5 from "md5";
export default {
  name: "Login",
  data() {
    return {
      account: "",
      password: "",
      showError: false,
      errorText: "",
      imageUrl:require('../assets/img/logo.png'),
    };
  },
  mounted() {
			// this.updateHeaderLine();
 			// this.getLoginImage();
      // this.getFaviconImg();
		},
  methods: {
    onLogin() {
      let that = this;
      that
        .$http({
          url:that.$baseURL + "app/Chat/CustomerAgent/login",
          method: "post",
          data: {
            number: that.account,
            password: that.password,
          },
        })
        .then((res) => {
          let data = res.data;
          if (data.status == 200) {
            // if (localStorage.account !== that.account) {
              // that.$store.commit("clearMessageCache");
            // }
            that.$store.commit("setNumber", data.data.number);
            that.$store.commit("setToken", data.data.token);
           
            sessionStorage.setItem('partner_id', '');
            sessionStorage.setItem('partner_id', data.data.partner_id);
            
            // that.getFaviconImg();
            that.$vToastify.success({
              title: "Success",
              body: data.msg,
              type: "success",
              canTimeout: true,
              duration: 3000,
              position: "top",
            });

            that.$router.replace({ name: "ChatWindow" });
            window.location.reload();
            
          } else {
            that.showError = true;
            that.errorText = "手机号码或登录密码错误";
          }
        }).catch((e)=>{
          console.error(e.message);
        });
    },
//     getLoginImage() {
//     console.log("get login image");
// 	console.log(this.$route.query.token);
//     let context = this;
//     context.$http({
//         url: context.$baseURL + "app/image/getImage/" + context.$route.query.token +"?key=all.home.home.logo",
//         method: 'get',
//     }, (errorMsg) => {
//         this.showToast({
//             type: 'error',
//             message: errorMsg,
//         });
//     }).then(res => {
//         context.imageUrl = res.data.data;
//         if(context.imageUrl == undefined){
//           context.imageUrl = require('../assets/img/logo.png')
//         }
//         console.log(context.imageUrl);
//     });
// },
// getFaviconImg() {
//       var url = this.$route.fullPath;
//       var token = ''
//       var tokenIndex = url.indexOf("token=");
//         if (tokenIndex !== -1) {
//           token = url.substring(tokenIndex + 6);
//           sessionStorage.setItem('partner_id', token);
//         }

//       this
//         .$http({
//           url: this.$baseURL + `app/image/getTitleData/?token=${token}&keyForLogo=all.home.home.logo&keyForTitle=all.home.partner.title`,
//           method: "get",
//           headers: {
//             'PartnerId': sessionStorage.getItem("partner_id") ? sessionStorage.getItem("partner_id") : ''
//           },
//         })
//         .then(function (res) {
//           if (res.data.status == 200) {
//             sessionStorage.setItem("faviconImg", res.data.data.imageUrl)
//           }
//         });
//     },
  },
};
</script>

<style scoped>
.headerStyle {
  background: #ffffff;
}

.footerStyle {
  background: #ffffff;
  padding: 20px 50px;
}

.btn-success {
  background-color: #28a745;
}

.imageLogo {
  border-radius: 50%;
  width: 100px;
  margin: 30px 0px;
  box-shadow: rgba(14, 30, 37, 0.12) 0px 2px 4px 0px,
    rgba(14, 30, 37, 0.32) 0px 2px 16px 0px;
}
</style>