<template>
    <!-- ========================================= Preview Red Packet Modal ============================================ -->

    <b-modal v-model="previewRedPacket" centered size="l" @show="modelOpen"  @hidden="closeModel" hide-header hide-footer >

        <template #default>
            <b-row class="red-packet-row">
                <b-col>
            <div style="display: flex; justify-content: center; align-items: center;">

            <img :src="redPackDetails.image ? redPackDetails.image : require('../../assets/img/redpackets.png')" width="200" height="200"  />
            </div>
           </b-col>
           <b-col class="separator-column">
            <p class="previewDetails">
          <span style=" margin-left: 5px; font-size: 24px; color: red">{{ redPackDetails.amount }}¥</span>
        </p>
            <p class="previewDetails">
          红包领取状态:<span style="color: red; margin-left: 5px;">{{ redPackDetails.status == 0 ? '不' :redPackDetails.status == 1? '是的': '无状态' }}</span>
        </p>
            <p class="previewDetails">
          红包到期在:<span style="margin-left: 5px;">{{redPackDetails.expire_at}}</span>
        </p>
       
        </b-col>
    </b-row>
        </template>
    </b-modal>
</template>

<script>

export default {
  name: "previewModel",
  props:{
    previewRedPacket: {
				type: Boolean,
				default: false,
			},
    redPackDetails: {
				type: Object,
				default: {},
			}
  },
  data() {
    return {
        // sendRedPacketModal:false,
        selectedRedPacket:'',
        amount:0,
        expireData:[]

    }
},
methods: {
    closeModel(){
        this.$emit('redPackPreviewClose')
    },
    modelOpen(){
        let expireDetails = this.redPackDetails.expire_at
        this.expireData = expireDetails.split(' ')
    }
}



}
</script>
<style>
.previewDetails{
    display: flex; 
    align-items: right;
    
}
.modal-body{
   padding: 50px;
   border-radius: 20px;
   /* background: rgb(67, 95, 122,0.8); */
   color: #fff
}
.modal-content{
    background: rgb(44, 62, 80);

}
.modal-open .modal{
    background: rgba(0, 0, 0, 0.4);
}
.red-packet-row {
    align-items: center;
}

.separator-column {
    border-left: 0.2px solid #848484;
    padding-left: 15px;
}
</style>
