import Vue from 'vue'
import App from './App.vue'
import { BootstrapVue, IconsPlugin } from 'bootstrap-vue'
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'
import { BootstrapVueIcons } from 'bootstrap-vue'
import router from './router'
import axios from "axios";
import Qs from "qs";
import store from "./store/store.js"
import VueToastify from "vue-toastify";
import JSONbig from "json-bigint";

Vue.config.productionTip = false
Vue.use(BootstrapVue)
Vue.use(IconsPlugin)
Vue.use(BootstrapVueIcons)
Vue.use(VueToastify,{
  my: {
    position:"top-right"
  }});

// axios.defaults.transformRequest = [function (data) {
//   if (data.toString() == "[object FormData]") {
//     return data;
//   } else {
//     return Qs.stringify(data)
//   }
// }];
// axios.defaults.transformResponse=[(data) => {
//   if (typeof data === 'string') {
//     try {
//       data = JSONbig.parse(data);
//     } catch (e) { /* Ignore */ } // Added this Ignore as it's the same in the Axios
//   }
//   return data;
// }];

const instance = axios.create({
  baseURL: Vue.prototype.$baseURL, //process.env.API_ROOT
  timeout: 10000,
  withCredentials: true,
  headers: {
    'Content-Type': 'application/json',
    'Authorization': store.state.token
  }
});

instance.interceptors.request.use(
  (config) => {
    config.headers['Authorization'] = store.state.token;
    config.headers['PartnerId'] = sessionStorage.getItem("partner_id");
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);
Vue.prototype.$http = instance;
// Vue.prototype.$baseURL = "https://chatapp.hk9999z.com/";  // for test
// Vue.prototype.$baseURL = "https://client1chat.hk9999z.com/";  // for test

// Vue.prototype.$baseURL = "https://chat.hk99555.com/";   // for live
// Vue.prototype.$baseURL = "https://fhzqchatapi.hk9999z.com/";   // for beta
Vue.prototype.$baseURL = "https://indchatappapi.hk9999z.com/";   // for live


Vue.prototype.$baseStoragePath = Vue.prototype.$baseURL+"files/chat-space/";

// const instance = axios.create({
//   baseURL: Vue.prototype.$baseURL,//"https://chatapp.hk9999z.com/",
//   timeout: 30000,
//   withCredentials: true
// });
// Vue.prototype.$http = instance;

new Vue({
  router,store,
  render: h => h(App)
}).$mount('#app')